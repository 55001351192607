import {
  GET_ALL_UNIVS,
  ADD_UNIV,
  GET_UNIV,
  LIKE_UNIV,
  UNLIKE_UNIV,
  LOADING_DATA,
  DELETE_UNIV,
  SUBMIT_COMMENT_ON_UNIV,
} from "../types";

const initialState = {
  allUnivs: [],
  univData: {},
  loadingData: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loadingData: true,
      };
    case GET_ALL_UNIVS:
      return {
        ...state,
        allUnivs: action.payload,
        loadingData: false,
      };
    case ADD_UNIV:
      return {
        ...state,
        allUnivs: [action.payload, ...state.allUnivs],
      };
    case GET_UNIV:
      return {
        ...state,
        univData: { ...action.payload },
        loadingData: false,
      };
    case LIKE_UNIV:
    case UNLIKE_UNIV:
      // let index = state.univsData.findIndex(
      //   (univ) => univ.univId === action.payload.univId
      // );
      // state.univsData[index] = action.payload;
      if (state.univsData.univId === action.payload.univId) {
        state.univsData = action.payload;
      }
      return {
        ...state,
      };
    case DELETE_UNIV:
      let index = state.allUnivs.findIndex(
        (univ) => univ.univId === action.payload
      );
      console.log("index", index);
      state.allUnivs.splice(index, 1);
      return {
        ...state,
      };
    case SUBMIT_COMMENT_ON_UNIV:
      return {
        ...state,
        univsData: {
          ...state.univsData,
          comments: [action.payload, ...state.univsData.comments],
        },
      };
    default:
      return state;
  }
}
