import React, { Component } from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/Link";

import { connect } from "react-redux";

import UnivLogo from "../images/univLogo.png";
import LocationPin from "../images/locationPin.png";

// Actions Imports
import {
  getAllUnivs,
  getUniv,
  deleteUniv,
} from "../redux/actions/univDataActions";

// Mui
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// Styled Components
import {
  SCUinvContainer,
  SCUinvSingleCard,
  SCTable,
  SCWebsiteLinkButton,
  SCTagImage,
  SCUnivEditButton,
  SCDeleteButton,
} from "./styles";

class Univs extends Component {
  state = {
    univs: [],
  };

  componentDidMount() {
    this.props.getAllUnivs();
  }

  onUnivEdit(univId) {
    console.log("onuniedit univId", univId);
    this.props.getUniv(univId);
  }

  onUnivDelete(univId) {
    console.log("Delete univ ", univId);
    this.props.deleteUniv(univId, this.props.history);
  }

  render() {
    return (
      <div className="universitiesContainer">
        <h1>List of Universities</h1>
        <p>NO. of Universities {this.props.univsData.length}</p>
        <SCUinvContainer>
          {this.props.univsData.map((uni, index) => (
            <SCUinvSingleCard id={index}>
              <img
                className="univLogo"
                src={UnivLogo}
                alt={"University Logo"}
              ></img>
              <div className="card__details">
                <div className="allTags">
                  <span className="tag">{uni.city}</span>
                  <span className="tag">{uni.state}</span>
                  {uni.countryCode ? (
                    <span className="tag">{uni.countryCode}</span>
                  ) : null}
                  <a className="linkTag" href="none">
                    <SCTagImage
                      src={LocationPin}
                      alt={"Location Pin"}
                    ></SCTagImage>
                  </a>
                </div>
                <div className="name">{uni.univName}</div>
                <SCTable>
                  <tbody>
                    <td>Email</td>
                    <td>{uni.email}</td>
                  </tbody>
                  <tbody>
                    <td>Phone</td>
                    <td>{uni.phone}</td>
                  </tbody>
                  <tbody>
                    <td>Address</td>
                    <td>{uni.address}</td>
                  </tbody>
                  <tbody>
                    <td>Zip code</td>
                    <td>{uni.zipCode}</td>
                  </tbody>
                  <tbody>
                    <td>Country</td>
                    <td>{uni.country}</td>
                  </tbody>
                </SCTable>
                {this.props.userAuthenticated ? (
                  <SCUnivEditButton
                    size="medium"
                    startIcon={<EditIcon />}
                    onClick={() => this.onUnivEdit(uni.univId)}
                    component={Link}
                    to="/editUniv"
                  ></SCUnivEditButton>
                ) : null}
                <SCWebsiteLinkButton
                  className="webLink"
                  disable={uni.websiteLink != null ? true : false}
                >
                  <a href={uni.websiteLink}>{uni.website} </a>
                </SCWebsiteLinkButton>
                {this.props.userAuthenticated ? (
                  <SCDeleteButton
                    startIcon={<DeleteIcon />}
                    onClick={() => this.onUnivDelete(uni.univId)}
                  ></SCDeleteButton>
                ) : null}
              </div>
            </SCUinvSingleCard>
          ))}
        </SCUinvContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  univsData: state.univsData.allUnivs,
  userAuthenticated: state.usersData.authenticated,
  userRole: state.usersData.role,
});

const mapActionsToProps = {
  getAllUnivs,
  getUniv,
  deleteUniv,
};

export default connect(mapStateToProps, mapActionsToProps)(Univs);
