import React, { Component } from "react";
import PropTypes from "prop-types";
import AppIcon from "../images/ej.JPG";

// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// Redux stuff
import { connect } from "react-redux";
import { loginUser } from "../redux/actions/userActions";

// Styled Components
import {
  SCLoginImage,
  SCLoginContainer,
  SCLoginGrid,
  SCLoginForm,
  SCLogInButton,
  SCCircularProgress,
  SCCredsErrorTypography,
  SCTextField,
} from "./styles";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({ errors: nextProps.UI.errors });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    console.log("login userData  = ", userData);

    this.props.loginUser(userData, this.props.history);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    const {
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    return (
      <SCLoginContainer container>
        <Grid item sm />
        <SCLoginGrid item sm>
          <SCLoginImage src={AppIcon} alt="edujock" />
          <br />
          <br />
          <Typography variant="h5"> </Typography>
          <SCLoginForm noValidate onSubmit={this.handleSubmit}>
            <br />
            <SCTextField
              id="email"
              name="email"
              type="email"
              label="Email"
              helperText={errors.email}
              error={errors.email ? true : false}
              value={this.state.email}
              onChange={this.handleChange}
              fullWidth
              required
            />
            <br />
            <br />
            <br />

            <SCTextField
              id="password"
              name="password"
              type="password"
              label="Password"
              helperText={errors.password}
              error={errors.password ? true : false}
              value={this.state.password}
              onChange={this.handleChange}
              fullWidth
              required
            />
            {/* TODO - Should be red */}
            {errors.general && (
              <SCCredsErrorTypography variant="body2">
                {errors.general}
              </SCCredsErrorTypography>
            )}
            <SCLogInButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Login
              {loading && <SCCircularProgress size={30} />}
            </SCLogInButton>
            <br />
            <small>
              Forgot Password ? Contact admin at{" "}
              <a href={`mailto: ${this.state.adminEmail}`}>admin@edujock.com</a>
              ;
            </small>
          </SCLoginForm>
        </SCLoginGrid>
        <Grid item sm />
      </SCLoginContainer>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.usersData,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
