import {
  GET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  GET_ALL_USERS,
} from "../types";
import axios from "axios";

const setAuthorizationHeader = (token) => {
  const FBUserToken = `Bearer ${token}`;
  localStorage.setItem("FBUserToken", FBUserToken);
  // axios.defaults.headers.common["Authorization"] = FBUserToken;

  if (token !== undefined && typeof token === "string") {
    axios.defaults.headers.common["Authorization"] = FBUserToken;
  }
};

export const getUserData = (email) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  const emailParams = email?.split("@")[0];
  axios
    .get(`/users/${emailParams}`)
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getAllUsers = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get("/admin/users")
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch({ type: GET_ALL_USERS, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUser = (userData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/login", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData(userData.email));
      dispatch({ type: CLEAR_ERRORS });
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const addUser = (newUserData, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/admin/adduser", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      // dispatch(getUserData(newUserData.email));
      dispatch({ type: CLEAR_ERRORS });
      history.push("/users");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("FBUserToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user/image", formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  axios
    .post(`/admin/users/${userDetails.userName}`, userDetails)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData(userDetails.email));
    })
    .catch((err) => console.log(err));
};

export const deleteUser = (userName) => (dispatch) => {
  dispatch({ type: LOADING_USER });

  axios
    .delete(`/admin/users/${userName}`)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getAllUsers());
    })
    .catch((err) => console.log(err));
};

export const markNotificationsRead = (notificationIds) => (dispatch) => {
  axios
    .post("/notifications", notificationIds)
    .then((res) => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ,
      });
    })
    .catch((err) => console.log(err));
};
