const getDate = (iso) => {
  const dateObj = new Date(iso);
  let year = dateObj.getFullYear();
  let month = dateObj.getMonth() + 1;
  let date = dateObj.getDate();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = monthNames[month - 1];
  }

  return month + " " + date + "th " + year;
};

export default getDate;
