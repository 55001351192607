import React, { Component } from "react";
import Link from "react-router-dom/Link";
import { connect } from "react-redux";

// Styled Components
import { SCNavBar, SCButton, SCAppBar, SCNavDpImage } from "./styles";

// Actions Imports
import { logoutUser, getAllUsers } from "../redux/actions/userActions";

class Navbar extends Component {
  render() {
    const onLogOut = () => {
      console.log("logeout");
      this.props.logoutUser();
      window.location.href = "/login";
    };

    const getUsers = () => {
      this.props.getAllUsers();
    };

    return (
      <SCAppBar>
        <SCNavBar>
          {!this.props.usersData.authenticated ? (
            <SCButton color="inherit" component={Link} to="./login">
              Login
            </SCButton>
          ) : (
            <SCButton color="inherit" onClick={onLogOut}>
              Log out
            </SCButton>
          )}

          <SCButton color="inherit" component={Link} to="./">
            Home
          </SCButton>

          {this.props.usersData.authenticated ? (
            <SCButton color="inherit" component={Link} to="./adduniv">
              Add University
            </SCButton>
          ) : null}
          {this.props.usersData.authenticated ? (
            <div className="profileNameDp">
              <SCNavDpImage
                src={this.props.usersData.dpUrl}
                alt="userDP"
              ></SCNavDpImage>
              <SCButton color="inherit" component={Link} to="./myprofile">
                {this.props.usersData.userName}'s Profile
              </SCButton>
            </div>
          ) : null}

          {this.props.usersData.role === "admin" ? (
            <SCButton
              color="inherit"
              component={Link}
              to="./users"
              onClick={getUsers}
            >
              Users
            </SCButton>
          ) : null}
          {this.props.usersData.role === "admin" ? (
            <SCButton color="inherit" component={Link} to="./addUser">
              Add User
            </SCButton>
          ) : null}
        </SCNavBar>
      </SCAppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  usersData: state.usersData,
});

const mapActionsToProps = {
  logoutUser,
  getAllUsers,
};

export default connect(mapStateToProps, mapActionsToProps)(Navbar);
