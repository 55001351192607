// User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const GET_USER = "GET_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ADD_USER = "ADD_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";
// UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
// Univ data reducer types
export const GET_ALL_UNIVS = "GET_ALL_UNIVS";
export const GET_UNIV = "GET_UNIV";
export const ADD_UNIV = "ADD_UNIV";
export const EDIT_UNIV = "EDIT_UNIV";
export const LOAD_UNIV_EDIT_PAGE = "LOAD_UNIV_EDIT_PAGE";
export const LIKE_UNIV = "LIKE_UNIV";
export const UNLIKE_UNIV = "UNLIKE_UNIV";
export const DELETE_UNIV = "DELETE_UNIV";
export const SUBMIT_COMMENT_ON_UNIV = "SUBMIT_COMMENT_ON_UNIV";
