import {
  GET_ALL_UNIVS,
  ADD_UNIV,
  LOADING_DATA,
  EDIT_UNIV,
  GET_UNIV,
  DELETE_UNIV,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  // SET_SCREAM,
  STOP_LOADING_UI,
  // SUBMIT_COMMENT,
} from "../types";
import axios from "axios";

const setAuthorizationHeader = (token) => {
  const FBUserToken = `Bearer ${token}`;
  localStorage.setItem("FBUserToken", FBUserToken);
  // axios.defaults.headers.common["Authorization"] = FBUserToken;

  if (token !== undefined && typeof token === "string") {
    axios.defaults.headers.common["Authorization"] = FBUserToken;
  }
};

// Get all Univs
export const getAllUnivs = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });

  axios
    .get(`/univs`)
    .then((res) => {
      console.log("res.data == ", res.data);
      dispatch({
        type: GET_ALL_UNIVS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ALL_UNIVS,
        payload: [],
      });
    });
};
export const getUniv = (univId) => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/univ/${univId}`)
    .then((res) => {
      console.log("res.data == ", res.data);
      dispatch({
        type: GET_UNIV,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
// Add Unniv
export const addUniv = (univData, history) => (dispatch) => {
  console.log("add univ data = ", univData);
  dispatch({ type: LOADING_UI });
  axios
    .post("/adduniv", univData)
    .then((res) => {
      dispatch({
        type: ADD_UNIV,
        payload: res.data,
      });
      dispatch(clearErrors());
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const editUniv = (univData, history) => (dispatch) => {
  console.log("univDAta edit univ ", univData);
  axios
    .post(`/univ/${univData.univId}`, univData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch({
        type: EDIT_UNIV,
        payload: res.data,
      });
      dispatch(clearErrors());
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteUniv = (univId, history) => (dispatch) => {
  axios
    .delete(`/univ/${univId}`)
    .then((res) => {
      // setAuthorizationHeader(res.data.token);
      dispatch({ type: DELETE_UNIV, payload: univId });
      // dispatch(getAllUnivs());
      history.push("/");
    })
    .catch((err) => console.log(err));
};
// Like a scream
// export const likeScream = (screamId) => (dispatch) => {
//   axios
//     .get(`/scream/${screamId}/like`)
//     .then((res) => {
//       dispatch({
//         type: LIKE_SCREAM,
//         payload: res.data,
//       });
//     })
//     .catch((err) => console.log(err));
// };
// Unlike a scream
// export const unlikeScream = (screamId) => (dispatch) => {
//   axios
//     .get(`/scream/${screamId}/unlike`)
//     .then((res) => {
//       dispatch({
//         type: UNLIKE_SCREAM,
//         payload: res.data,
//       });
//     })
//     .catch((err) => console.log(err));
// };
// Submit a comment
// export const submitComment = (screamId, commentData) => (dispatch) => {
//   axios
//     .post(`/scream/${screamId}/comment`, commentData)
//     .then((res) => {
//       dispatch({
//         type: SUBMIT_COMMENT,
//         payload: res.data,
//       });
//       dispatch(clearErrors());
//     })
//     .catch((err) => {
//       dispatch({
//         type: SET_ERRORS,
//         payload: err.response.data,
//       });
//     });
// };
// export const deleteScream = (screamId) => (dispatch) => {
//   axios
//     .delete(`/scream/${screamId}`)
//     .then(() => {
//       dispatch({ type: DELETE_SCREAM, payload: screamId });
//     })
//     .catch((err) => console.log(err));
// };

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
