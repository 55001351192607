import React, { useEffect } from "react";

import { getAllUsers, deleteUser } from "../redux/actions/userActions";

import { useDispatch, useSelector } from "react-redux";

import getDate from "../utils/getDate";

// Mui
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";

// Styled Components
import { SCUinvContainer, SCTable, SCDpImage, SCDeleteButton } from "./styles";

function Users() {
  const allUsers = useSelector((state) => state.usersData.allUsers);
  const authenticated = useSelector((state) => state.usersData.authenticated);
  const dispatch = useDispatch();

  const onUserDelete = (userName) => {
    console.log("user deleted");
    dispatch(deleteUser(userName));
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  return authenticated ? (
    <div>
      <h1>Users Page</h1>
      <h3>No. of Users {allUsers.length}</h3>
      <SCUinvContainer>
        <Grid item md />
        {allUsers.map((user, index) => (
          <div id={index}>
            <SCDpImage src={user.dpUrl} alt="userDP"></SCDpImage>
            <SCTable>
              <tbody>
                <td>Name </td>
                <td>{user.name}</td>
              </tbody>
              <tbody>
                <td>User name </td>
                <td>{user.userName}</td>
              </tbody>
              <tbody>
                <td>email</td>
                <td>{user.email}</td>
              </tbody>
              <tbody>
                <td>role</td>
                <td>{user.role}</td>
              </tbody>
              <tbody>
                <td>addedBy</td>
                <td>{user.addedBy}</td>
              </tbody>
              <tbody>
                <td>createdAt</td>
                <td>{getDate(user.createdAt)}</td>
              </tbody>
              <SCDeleteButton
                startIcon={<DeleteIcon />}
                onClick={() => onUserDelete(user.userName)}
                disabled={user.role === "admin"}
              >
                Delete
              </SCDeleteButton>
            </SCTable>
          </div>
        ))}
        <Grid item md />
      </SCUinvContainer>
    </div>
  ) : (
    (window.location.href = "/login")
  );
}

export default Users;
