import React, { Component } from "react";
import PropTypes from "prop-types";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// Redux stuff
import { connect } from "react-redux";
import { editUniv } from "../redux/actions/univDataActions";

// Styled Components
import {
  SCLoginImage,
  SCLoginContainer,
  SCLoginGrid,
  SCLoginForm,
  SCLogInButton,
  SCCircularProgress,
  SCCredsErrorTypography,
  SCTextField,
} from "./styles";

class EditUniv extends Component {
  constructor() {
    super();
    this.state = {
      univName: "",
      website: "",
      websiteLink: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      stateCode: "",
      country: "",
      countryCode: "",
      zipCode: "",
      loading: false,
      errors: {},
    };
  }
  // componentWillReceiveProps(nextProps) {
  //   console.log("next props ", nextProps);
  //   if (nextProps.univsData) {
  //     this.setState({ ...nextProps.univsData });
  //   }

  //   if (nextProps.UI.errors) {
  //     this.setState({ errors: nextProps.UI.errors });
  //   }
  // }

  componentDidMount() {
    const univData = this.props.univsData.univData;

    this.setState({ ...this.state, ...univData });

    console.log("univ data this.props", univData);
  }
  handleSubmit = (event) => {
    event.preventDefault();

    const editUnivData = {
      univName: this.state.univName,
      website: this.state.website,
      websiteLink: this.state.websiteLink,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      stateCode: this.state.stateCode,
      country: this.state.country,
      countryCode: this.state.countryCode,
      zipCode: this.state.zipCode,
      univId: this.state.univId,
    };

    console.log("editUnivData ", editUnivData);

    this.props.editUniv(editUnivData, this.props.history);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  render() {
    const {
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    return !this.props.univsData.loadingData ? (
      <SCLoginContainer container>
        <Grid item sm />
        <SCLoginGrid item sm>
          <Typography variant="h5">Editing {this.state.univName}</Typography>
          <SCLoginForm noValidate onSubmit={this.handleSubmit}>
            <br />
            <br />
            <br />
            <SCTextField
              id="univName"
              name="univName"
              type="text"
              label="University Name"
              helperText={errors.univName}
              error={errors.univName ? true : false}
              value={this.state.univName}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="website"
              name="website"
              type="text"
              label="Website"
              helperText={errors.website}
              error={errors.website ? true : false}
              value={this.state.website}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="websiteLink"
              name="websiteLink"
              type="text"
              label="Website link"
              helperText={errors.websiteLink}
              error={errors.websiteLink ? true : false}
              value={this.state.websiteLink}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="email"
              name="email"
              type="email"
              label="Email"
              helperText={errors.email}
              error={errors.email ? true : false}
              value={this.state.email}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="phone"
              name="phone"
              type="text"
              label="Phone"
              helperText={errors.phone}
              error={errors.phone ? true : false}
              value={this.state.phone}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="address"
              name="address"
              type="text"
              label="Address"
              helperText={errors.address}
              error={errors.address ? true : false}
              value={this.state.address}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="city"
              name="city"
              type="text"
              label="city"
              helperText={errors.city}
              error={errors.city ? true : false}
              value={this.state.city}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="state"
              name="state"
              type="text"
              label="state"
              helperText={errors.state}
              error={errors.state ? true : false}
              value={this.state.state}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="stateCode"
              name="stateCode"
              type="text"
              label="stateCode"
              helperText={errors.stateCode}
              error={errors.stateCode ? true : false}
              value={this.state.stateCode}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="country"
              name="country"
              type="text"
              label="country"
              helperText={errors.country}
              error={errors.country ? true : false}
              value={this.state.country}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="countryCode"
              name="countryCode"
              type="text"
              label="Country code"
              helperText={errors.countryCode}
              error={errors.countryCode ? true : false}
              value={this.state.countryCode}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="zipCode"
              name="zipCode"
              type="text"
              label="Zip code"
              helperText={errors.zipCode}
              error={errors.zipCode ? true : false}
              value={this.state.zipCode}
              onChange={this.handleChange}
              fullWidth
            />
            {/* for user role */}
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={""}
                onChange={() => {
                  console.log("onchange");
                }}
                label="Age"
              >
                <MenuItem value={10}>US</MenuItem>
                <MenuItem value={20}>CA</MenuItem>
                <MenuItem value={30}>UK</MenuItem>
              </Select>
            </FormControl>
            {errors.general && (
              <SCCredsErrorTypography variant="body2">
                {errors.general}
              </SCCredsErrorTypography>
            )}
            <SCLogInButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Save
              {loading && <SCCircularProgress size={30} />}
            </SCLogInButton>
            <br />
          </SCLoginForm>
        </SCLoginGrid>
        <Grid item sm />
      </SCLoginContainer>
    ) : (
      <p>loading...</p>
    );
  }
}

EditUniv.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  univsData: PropTypes.object.isRequired,
  editUniv: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.usersData,
  UI: state.UI,
  univsData: state.univsData,
});

const mapActionsToProps = {
  editUniv,
};

export default connect(mapStateToProps, mapActionsToProps)(EditUniv);
