import React, { Component } from "react";

// Styled Components
import { SCCard, SCCardHeader, SCCardMedia, SCButton } from "./styles";

class loginOld extends Component {
  render() {
    return (
      <div className="loginForm">
        <SCCard>
          <SCCardHeader></SCCardHeader>
          <SCCardMedia src={"../images/wallpic.png"} />
          <SCButton></SCButton>
        </SCCard>
        <h1>Login Page</h1>
      </div>
    );
  }
}

export default loginOld;
