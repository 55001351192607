import {
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  GET_USER,
  ADD_USER,
  LOADING_USER,
  LIKE_UNIV,
  UNLIKE_UNIV,
  MARK_NOTIFICATIONS_READ,
  GET_ALL_USERS,
} from "../types";

const initialState = {
  name: "",
  userName: "",
  role: "",
  dpUrl: "",
  authenticated: false,
  loading: false,
  allUsers: [],
  credentials: {},
  notifications: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case GET_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        // userName: action.payload.email.split("@")[0],
        ...action.payload,
      };
    case GET_ALL_USERS:
      console.log("all users = ", action.payload);
      return {
        ...state,
        authenticated: true,
        loading: false,
        allUsers: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        loading: true,
      };
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case LIKE_UNIV:
      return {
        ...state,
        likes: [
          ...state.likes,
          {
            userName: state.credentials.userName,
            univId: action.payload.univId,
          },
        ],
      };
    case UNLIKE_UNIV:
      return {
        ...state,
        likes: state.likes.filter(
          (like) => like.univId !== action.payload.univId
        ),
      };
    case MARK_NOTIFICATIONS_READ:
      state.notifications.forEach((not) => (not.read = true));
      return {
        ...state,
      };
    default:
      return state;
  }
}
