import styled from "styled-components";
// Mui
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";

export const SCCard = styled.div`
  height: 200px;
  width: 30%;
  display: flex;
  flex-direction: column;
  border: 1px red solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const SCCardHeader = styled.div`
  height: 30%;
  background: red;
  color: white;
  text-align: center;
`;

export const SCCardMedia = styled.img`
  height: 200px;
  width: 100px;
`;

export const SCButton = styled(Button)`
  &.MuiButton-colorInherit {
    color: black;
    background: transparent;
    border-radius: 15px;
    border: 2px solid #114457;
  }

  &.MuiButton-root:hover {
    text-decoration: none;
    background-color: #2d4662;
    color: white;
  }

  &.MuiButton-containedPrimary {
    color: #fff;
    background-color: dimgray;
  }
`;

// Log in Styles

export const SCLoginImage = styled.img`
  height: 120px;
  width: 200px;
`;

export const SCLoginContainer = styled(Grid)``;

export const SCLoginGrid = styled(Grid)`
  @media (min-width: 600px) {
    &.MuiGrid-grid-sm-true {
      flex-grow: 1;
      max-width: 100%;
      flex-basis: 0;
      margin-top: 100px;
    }
  }
`;

export const SCLoginForm = styled.form`
  margin-top: 10px;
`;

export const SCTextField = styled(TextField)`
  label {
    &.MuiFormLabel-root.Mui-focused {
      color: black;
    }
  }

  &.MuiFormControl-fullWidth {
    width: 100%;
    height: 70px;
  }

  div {
    &.MuiInput-underline:after {
      border-bottom: 1px solid rgb(225, 175, 122);
    }
  }
`;

export const SCLogInButton = styled(Button)`
  &.MuiButton-containedPrimary {
    color: black;
    background-color: white;
    margin: 40px;
  }

  &.MuiButton-root:hover {
    text-decoration: none;
    background-color: #2d4662;
    color: white;
  }
`;

export const SCCircularProgress = styled(CircularProgress)`
  position: absolute;
  &.MuiCircularProgress-colorPrimary {
    color: white;
  }
`;

export const SCCredsErrorTypography = styled(Typography)`
  &.MuiTypography-body2 {
    font-size: 17px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.7;
    letter-spacing: 0.01071em;
    color: firebrick;
    margin-top: 15px;
    margin-bottom: -30px;
  }
`;

// Univs Styled

export const SCUinvContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  height: 510px;
  padding: 50px;
  flex-flow: wrap;

  @media screen and (max-width: 386px) {
    padding: 0px;
  }
`;

export const SCUinvCard = styled.div`
  height: 200px;
  width: 90px;
  display: flex;
  flex-direction: row;
  border: 1px red solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const SCUinvSingleCard = styled.div`
  width: 300px;
  background-color: white;
  border: 1px solid #bacdd8;
  padding: 8px;
  border-radius: 12px;
  margin: 0 auto;
  margin-top: 20px;

  .univLogo {
    width: 100%;
    border-radius: 10%;
    border: 1px solid grey;
    height: 200px;
    object-fit: contain;
  }

  .name {
    font-size: 24px;
    margin-top: 15px;
  }

  .tag {
    padding: 4px 8px;
    border: 1px solid #e5eaed;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    color: #788697;
    float: left;
  }

  p {
    font-size: 14px;
    color: #7f8c9b;
  }

  .webLink {
    border: none;
    padding: 12px 24px;
    border-radius: 50px;
    color: #0077ff;
    background-color: #e0efff;
    margin: 0 auto;

    a {
      text-decoration: none;
      text-transform: lowercase;
    }

    button {
      border: none;
      padding: 12px 24px;
      border-radius: 50px;
      font-weight: 600;
      color: #0077ff;
      background-color: #e0efff;
      margin: 0 auto;
      display: block;
      cursor: pointer;
    }
  }

  .card__details {
    padding: 16px 8px 8px 8px;
  }

  .allTags {
    display: flow-root;
    width: 285px;
  }
`;

export const SCTagImage = styled.img`
  height: 30px;
  margin-top: -5px;
  float: right;
`;

export const SCTable = styled.table`
  width: 100%;
  margin-bottom: 10px;
  margin: 10px 0;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    border-radius: 10px;
  }

  tbody:nth-child(even) {
    background-color: #dddddd;
  }
`;

export const SCWebsiteLinkButton = styled(Button)`
  &.MuiButton-containedPrimary {
    border: none;
    padding: 12px 24px;
    border-radius: 50px;
    font-weight: 600;
    color: #0077ff;
    background-color: #e0efff;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }

  &.MuiButton-root:hover {
    text-decoration: none;
    background-color: #2d4662;
    color: white;
  }
`;

export const SCUnivEditButton = styled(Button)`
  .MuiButton-root {
    justify-content: start;
  }

  &.MuiButton-colorInherit {
    color: black;
    background: transparent;
    border-radius: 15px;
    border: 2px solid #114457;
  }

  &.MuiButton-root:hover {
    text-decoration: none;
    background-color: #2d4662;
    color: white;
  }

  &.MuiButton-containedPrimary {
    color: #fff;
    background-color: dimgray;
  }
`;

// Users

export const SCDpImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
`;

export const SCMyProfileImage = styled.img`
  width: 350px;
  height: 250px;
  object-fit: contain;
`;

export const SCFormControlUserRole = styled(FormControl)`
  width: 350px;
  height: 250px;
  object-fit: contain;

  &.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: unset;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black;
    border-width: 2px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: black;
  }
`;

export const SCDeleteButton = styled(Button)`
  &.MuiButton-root {
    color: #f04646;
    background: transparent;
    border-radius: 15px;
    border: 2px solid #e46763;
    text-transform: uppercase;
  }

  &.MuiButton-root:hover {
    text-decoration: none;
    background-color: #ff0901;
    color: white;
  }
`;

// User My Profile

export const SCEditinput = styled.input`
  border: none;
  font: 400 16px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  width: 150px;
  padding: 0px;
  &:focus-visible {
    outline: none;
  }
`;

export const SCEditIcon = styled(EditIcon)`
  color: black;
`;
