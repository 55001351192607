import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import getDate from "../utils/getDate";

import { editUserDetails } from "../redux/actions/userActions";

// Mui
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";

// Styled Components
import {
  SCUinvContainer,
  SCTable,
  SCMyProfileImage,
  SCEditinput,
  SCEditIcon,
  SCButton,
} from "./styles";

function MyProfile() {
  const currentUser = useSelector((state) => state.usersData);

  const [userData, setUserData] = useState({});

  const [editField, setEditField] = useState({});

  const [inputFieldVal, setInputFieldVal] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setUserData({ ...currentUser });
  }, [currentUser]);

  console.log("userData == ", userData);
  const editUserData = (field) => {
    setEditField({ [field]: true });
  };

  const inputOnBlur = (e) => {
    setEditField({ [e.target.name]: false });
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setInputFieldVal({
      [e.target.name]: e.target.value,
    });
    if (e.key === "Enter") {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      });
      setEditField({ [e.target.name]: false });
    }
  };

  const onKeyDownHandle = (e) => {
    if (e.key === "Enter") {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      });
      setEditField({ [e.target.name]: false });
    }
  };

  const onMyProfileSave = () => {
    dispatch(editUserDetails(userData));
  };

  return (
    <div>
      <h1>{userData.name} Profile</h1>
      <SCUinvContainer>
        <Grid item sm />
        <div>
          <SCMyProfileImage
            src={userData.dpUrl}
            alt="userDP"
          ></SCMyProfileImage>
          <SCTable>
            <tbody>
              <td>Name </td>
              {editField.name ? (
                <td>
                  <SCEditinput
                    name="name"
                    onChange={handleChange}
                    onKeyDown={onKeyDownHandle}
                    onBlur={inputOnBlur}
                    value={inputFieldVal.name} // TODO - not to blank the value in input
                  ></SCEditinput>
                </td>
              ) : (
                <td>{userData.name}</td>
              )}
              <div>
                <IconButton
                  size="small"
                  id="name"
                  aria-label="delete"
                  onClick={() => editUserData("name")}
                  name="name"
                >
                  <SCEditIcon fontSize="inherit" />
                </IconButton>
              </div>
            </tbody>
            <tbody>
              <td>User name </td>
              <td>{userData.userName}</td>
            </tbody>
            <tbody>
              <td>Email</td>
              <td>{userData.email}</td>
            </tbody>
            <tbody>
              <td>Password</td>
              <td>{userData.password}</td>
            </tbody>
            <tbody>
              <td>Role</td>
              <td>{userData.role}</td>
            </tbody>
            <tbody>
              <td>Added by</td>
              <td>{userData.addedBy}</td>
            </tbody>
            <tbody>
              <td>Created on</td>
              <td>{getDate(userData.createdAt)}</td>
            </tbody>
          </SCTable>
          <SCButton
            size="medium"
            startIcon={<SaveIcon />}
            onClick={() => onMyProfileSave()}
          >
            Save
          </SCButton>
        </div>
        <Grid item sm />
      </SCUinvContainer>
    </div>
  );
}

export default MyProfile;
