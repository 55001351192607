import React, { Component } from "react";
import PropTypes from "prop-types";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from '@material-ui/core/FormHelperText';
import Select from "@material-ui/core/Select";
// Redux stuff
import { connect } from "react-redux";
import { addUser } from "../redux/actions/userActions";

// Styled Components
import {
  SCLoginImage,
  SCLoginContainer,
  SCLoginGrid,
  SCLoginForm,
  SCLogInButton,
  SCCircularProgress,
  SCCredsErrorTypography,
  SCTextField,
  SCFormControlUserRole,
} from "./styles";

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
      loading: false,
      errors: {},
    };
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.UI.errors) {
  //     this.setState({ errors: nextProps.UI.errors });
  //   }
  // }
  handleSubmit = (event) => {
    event.preventDefault();

    const newUserData = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      role: this.state.role,
    };

    newUserData.userName = this.state.email.split("@")[0];
    console.log("login userData  = ", newUserData);

    this.props.addUser(newUserData, this.props.history);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleRoleSelect = (e) => {
    this.setState({
      role: e.target.value,
    });

    console.log("this.state.role", this.state.role);
  };

  render() {
    console.log("this.state", this.state);
    const {
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    return (
      <SCLoginContainer container>
        <Grid item sm />
        <SCLoginGrid item sm>
          <Typography variant="h5">Add User Page</Typography>
          <SCLoginForm noValidate onSubmit={this.handleSubmit}>
            <br />
            <SCTextField
              id="name"
              name="name"
              type="text"
              label="Name"
              helperText={errors.name}
              error={errors.name ? true : false}
              value={this.state.name}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="email"
              name="email"
              type="email"
              label="Email"
              helperText={errors.email}
              error={errors.email ? true : false}
              value={this.state.email}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="password"
              name="password"
              type="password"
              label="Password"
              helperText={errors.password}
              error={errors.password ? true : false}
              value={this.state.password}
              onChange={this.handleChange}
              fullWidth
            />
            <SCTextField
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              helperText={errors.confirmPassword}
              error={errors.confirmPassword ? true : false}
              value={this.state.confirmPassword}
              onChange={this.handleChange}
              fullWidth
            />
            {/* <SCTextField
              id="role"
              name="role"
              type="text"
              label="role"
              helperText={errors.role}
              error={errors.role ? true : false}
              value={this.state.role}
              onChange={this.handleChange}
              fullWidth
            /> */}
            {/* for user role */}
            <SCFormControlUserRole variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.role}
                onChange={this.handleRoleSelect}
                label="Role"
              >
                <MenuItem value={"user"}>User</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </SCFormControlUserRole>
            {errors.general && (
              <SCCredsErrorTypography variant="body2">
                {errors.general}
              </SCCredsErrorTypography>
            )}
            <SCLogInButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Add User
              {loading && <SCCircularProgress size={30} />}
            </SCLogInButton>
            <br />
          </SCLoginForm>
        </SCLoginGrid>
        <Grid item sm />
      </SCLoginContainer>
    );
  }
}

AddUser.propTypes = {
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.usersData,
  UI: state.UI,
});

const mapActionsToProps = {
  addUser,
};

export default connect(mapStateToProps, mapActionsToProps)(AddUser);
