import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwtDecode from "jwt-decode";

import "./App.css";
// Components
import Navbar from "./components/Navbar";

// Utils
import AuthRoute from "./utils/authRoute";

// Pages
import Login from "./pages/login";
import loginOld from "./pages/loginOld";
import Univs from "./pages/univs";
import AddUniv from "./pages/addUniv";
import Users from "./pages/users";
import AddUser from "./pages/addUser";
import MyProfile from "./pages/myProfile";
import EditUniv from "./pages/editUniv";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { SET_AUTHENTICATED, SET_UNAUTHENTICATED } from "./redux/types";
import { logoutUser, getUserData } from "./redux/actions/userActions";

import axios from "axios";

axios.defaults.baseURL =
  "https://us-central1-uniedujock.cloudfunctions.net/api";

const token = localStorage.FBUserToken;
if (typeof token === "string") {
  const decodedToken = jwtDecode(token);
  console.log("token if == ", token, "   decodedToken  == ", decodedToken.exp);
  if (decodedToken.exp * 1000 < Date.now() || token === undefined) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
} else {
  console.log("token null or undefined ", token);
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Router>
            <div className="appContainer">
              <Navbar />
              <Switch>
                <AuthRoute exact path="/login" component={Login}></AuthRoute>
                <Route exact path="/" component={Univs}></Route>
                <Route exact path="/addUniv" component={AddUniv}></Route>
                <Route path="/editUniv" component={EditUniv}></Route>
                <Route exact path="/users" component={Users}></Route>
                <Route exact path="/myprofile" component={MyProfile}></Route>
                <Route exact path="/loginOld" component={loginOld}></Route>
                <Route exact path="/adduser" component={AddUser}></Route>
              </Switch>
            </div>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;
