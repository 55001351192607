import styled from "styled-components";
// Mui
import Button from "@material-ui/core/Button";

export const SCAppBar = styled.div`
  background-color: white;
  color: white;
  display: flex;
  box-sizing: border-box;
  align-self: center;
`;

export const SCNavBar = styled.div`
  background: transparent;
  border-radius: 30px;
  border: 2px solid #114457;

  @media (min-width: 600px) {
    padding: 3px;
  }
`;

export const SCButton = styled(Button)`
  &.MuiButton-colorInherit {
    color: black;
    margin: 0 40px;
  }

  &.MuiButton-root:hover {
    text-decoration: none;
    background-color: #2d4662;
    color: white;
  }
`;

export const SCNavDpImage = styled.img`
  /* background-color: white;
  color: white;
  display: flex;
  box-sizing: border-box;
  align-self: center; */
  width: 30px;
  height: 30px;
  align-self: flex-end;
  margin-bottom: 4px;

  :hover {
    width: 60px;
    height: 60px;
  }
`;
